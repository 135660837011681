import React, { useState, useEffect } from "react";
import Web3 from "web3";
import "./metamask-auth.css";
import MetaMaskOnboarding from "@metamask/onboarding";
// import ReactLoading from "react-loading";
import {
  button_presale_already_minted,
  button_presale_coming_soon,
  button_presale_mint_not_whitelisted,
  button_presale_mint_whitelisted,
  button_public_mint,
  h1_presale_coming_soon,
  h1_presale_mint,
  h1_public_mint,
  h2_presale_coming_soon,
  h2_presale_mint,
  p_presale_coming_soon,
  p_presale_mint_already_minted,
  p_presale_mint_not_whitelisted,
  p_presale_mint_whitelisted,
  p_public_mint,
  mint_failed,
  abi,
  chain,
  contractAddress,
} from "./constants";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { FAQs } from "./faqs";

const ONBOARD_TEXT = "Click here to install MetaMask!";
const CONNECT_TEXT = "Connect";
const CONNECTED_TEXT = "Connected";
const TIMEOUT = 1000;
const COLLECTION_NAME = "OdysseusClub";
let editions = [];
let dots = 1;

const web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");

function MetaMaskAuth() {
  const [loader, setLoader] = useState(false);
  const [chainId, setChainId] = useState(null);
  const [buttonText, setButtonText] = React.useState(ONBOARD_TEXT);
  const [walletButtonDisabled, setWalletButtonDisabled] = React.useState(false);
  const [isMintDisabled, setMintDisabled] = React.useState(false);
  const [accounts, setAccounts] = React.useState([]);
  const onboarding = React.useRef();

  // function isMobileDevice() {
  //   return "ontouchstart" in window || "onmsgesturechange" in window;
  // }

  function countdown(preLogin) {
    const clockdiv = document.getElementById("countdown");
    const countDownTime = clockdiv.getAttribute("data-date") * 1000;

    const countdownfunction = setInterval(function () {
      const now = new Date().getTime();
      const diff = countDownTime - now;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      if (diff < 0) {
        clockdiv.style.display = "none";
        clearInterval(countdownfunction);
        // if (accounts.length === 0 && !preLogin) {
        //   console.log("trueuueueeueueueu")
        //   const mainHeading = document.getElementById("mainHeading");
        //   mainHeading.innerText =
        //     "Minting is open now. connect wallet to mint NFTs!";
        // }
      } else {
        clockdiv.style.display = "block";
        clockdiv.querySelector(".days").innerHTML = days;
        clockdiv.querySelector(".hours").innerHTML = hours;
        clockdiv.querySelector(".minutes").innerHTML = minutes;
        clockdiv.querySelector(".seconds").innerHTML = seconds;
      }
    }, 1000);
  }

  async function initialize() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      // checkChain();
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }

    if (window.web3) {
      // Check if User is already connected by retrieving the accounts
      await window.web3.eth.getAccounts().then(async (addr) => {
        // accountste = addr;
        setAccounts(addr);
      });
    }
  }

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    setTimeout(async () => {
      if (accounts.length === 0) {
        window.contract = new web3.eth.Contract(abi, contractAddress);
        window.info = await window.contract.methods.getInfo().call();
        let a = await window.info.runtimeConfig.publicMintStart;

        const clockdiv = document.getElementById("countdown");
        clockdiv.setAttribute("data-date", a);
        countdown(true);
      }
    }, 1000);
  }, [accounts]);

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        setButtonText(`✔ ...${accounts[0].slice(-4)}`);
        setWalletButtonDisabled(true);
        onboarding.current.stopOnboarding();

        window.address = accounts[0];
        window.contract = new web3.eth.Contract(abi, contractAddress);
        // checkOwner(accounts[0]);
        loadInfo();
      } else {
        setButtonText(CONNECT_TEXT);
        setWalletButtonDisabled(false);
      }
    }
  }, [accounts]);

  useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
      checkChain();
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum.on("chainChanged", () => window.location.reload());
      window.ethereum.on("accountsChanged", handleNewAccounts);
      return () => {
        window.ethereum.removeListener("accountsChanged", handleNewAccounts);
      };
    }
  }, []);

  const onClick = () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((newAccounts) => setAccounts(newAccounts));
    } else {
      onboarding.current.startOnboarding();
    }
  };

  const updateConnectStatus = async () => {
    onboarding.current = new MetaMaskOnboarding();
    const onboardButton = document.getElementById("connectWallet");
    const notConnected = document.querySelector(".not-connected");
    const spinner = document.getElementById("spinner");
    if (accounts && accounts.length > 0) {
      setButtonText(`✔ ...${accounts[0].slice(-4)}`);
      window.address = accounts[0];
      setWalletButtonDisabled(true);
      // checkOwner(accounts[0]);
      onboarding.current.stopOnboarding();
      window.contract = new web3.eth.Contract(abi, contractAddress);
      loadInfo();
    } else {
      onboardButton.onclick = async () => {
        await window.ethereum
          .request({
            method: "eth_requestAccounts",
          })
          .then(function (accts) {
            // onboardButton.innerText = `✔ ...${accts[0].slice(-4)}`;
            setButtonText(`✔ ...${accts[0].slice(-4)}`);
            notConnected.classList.remove("show-not-connected");
            notConnected.classList.add("hidden");
            // SHOW SPINNER
            spinner.classList.remove("hidden");
            // onboardButton.disabled = true;
            setWalletButtonDisabled(true);
            window.address = accts[0];
            setAccounts(accts);
            accounts = accts;
            // checkOwner(accounts[0]);
            window.contract = new web3.eth.Contract(abi, contractAddress);
            loadInfo();
          });
      };
    }
  };

  async function checkChain() {
    let chainId = 0;
    if (chain === "rinkeby") {
      chainId = 4;
    } else if (chain === "polygon") {
      chainId = 137;
    }
    if (window.ethereum.networkVersion !== chainId) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3.utils.toHex(chainId) }],
        });
        updateConnectStatus();
        loadInfo();
      } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask.
        // if (err.code === -32002) {
        //   alert('Please switch to Rinkeby Network to enable minting')
        // }
        // if (err.code === 4001) {
        //   alert("Please switch to Rinkeby Network to enable minting");
        // }
        if (err.code === 4902) {
          try {
            if (chain === "rinkeby") {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainName: "Rinkeby Test Network",
                    chainId: web3.utils.toHex(chainId),
                    nativeCurrency: {
                      name: "ETH",
                      decimals: 18,
                      symbol: "ETH",
                    },
                    rpcUrls: [
                      "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
                    ],
                  },
                ],
              });
            } else if (chain === "polygon") {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainName: "Polygon Mainnet",
                    chainId: web3.utils.toHex(chainId),
                    nativeCurrency: {
                      name: "MATIC",
                      decimals: 18,
                      symbol: "MATIC",
                    },
                    rpcUrls: ["https://polygon-rpc.com/"],
                  },
                ],
              });
            }
            updateConnectStatus();
          } catch (err) {
            console.log(err);
          }
        }
      }
    }
  }

  async function loadInfo() {
    window.info = await window.contract.methods.getInfo().call();
    const publicMintActive = await window?.contract.methods
      .mintingActive()
      .call();
    const presaleMintActive = await window?.contract.methods
      .presaleActive()
      .call();
    const mainHeading = document.getElementById("mainHeading");
    const subHeading = document.getElementById("subHeading");
    const mainText = document.getElementById("mainText");
    const actionButton = document.getElementById("actionButton");
    const mintContainer = document.getElementById("mintContainer");
    const mintButton = document.getElementById("mintButton");
    const spinner = document.getElementById("spinner");

    let startTime = "";
    if (publicMintActive) {
      mainHeading.innerText = h1_public_mint;
      mainText.innerText = p_public_mint;
      actionButton.classList.add("hidden");
      mintButton.innerText = button_public_mint;
      mintContainer.classList.remove("hidden");
      setTotalPrice();
    } else {
      // startTime = window.info.runtimeConfig.presaleMintStart;
      startTime = window.info.runtimeConfig.publicMintStart;

      mainHeading.innerText = h1_presale_mint;
      subHeading.innerText = h2_presale_coming_soon;
      mainText.innerText = p_presale_coming_soon;
      actionButton.innerText = button_presale_already_minted;
    }

    const clockdiv = document.getElementById("countdown");
    clockdiv.setAttribute("data-date", startTime);
    countdown();

    // HIDE SPINNER
    spinner.classList.add("hidden");

    // SHOW CARD
    setTimeout(() => {
      const countdownCard = document.querySelector(".countdown");
      countdownCard.classList.add("show-card");
    }, 1000);

    let priceType = "";
    if (chain === "rinkeby") {
      priceType = "ETH";
    } else if (chain === "polygon") {
      priceType = "MATIC";
    }
    const price = web3.utils.fromWei(
      window?.info.deploymentConfig.mintPrice,
      "ether"
    );
    const pricePerMint = document.getElementById("pricePerMint");
    const maxPerMint = document.getElementById("maxPerMint");
    const totalSupply = document.getElementById("totalSupply");
    const mintInput = document.getElementById("mintInput");

    pricePerMint.innerText = `${price} ${priceType}`;
    maxPerMint.innerText = `${window?.info.deploymentConfig.tokensPerMint}`;
    totalSupply.innerText = `${window?.info.deploymentConfig.maxSupply}`;
    mintInput.setAttribute("max", window?.info.deploymentConfig.tokensPerMint);

    // MINT INPUT
    const mintIncrement = document.getElementById("mintIncrement");
    const mintDecrement = document.getElementById("mintDecrement");
    const setQtyMax = document.getElementById("setQtyMax");
    const min = mintInput.attributes.min.value || false;
    const max = mintInput.attributes.max.value || false;
    mintDecrement.onclick = () => {
      let value = parseInt(mintInput.value) - 1 || 1;
      if (!min || value >= min) {
        mintInput.value = value;
        setTotalPrice();
      }
    };
    mintIncrement.onclick = () => {
      let value = parseInt(mintInput.value) + 1 || 1;
      if (!max || value <= max) {
        mintInput.value = value;
        setTotalPrice();
      }
    };
    setQtyMax.onclick = () => {
      mintInput.value = max;
      setTotalPrice();
    };
    mintInput.onchange = () => {
      setTotalPrice();
    };
    mintInput.onkeyup = async (e) => {
      if (e.keyCode === 13) {
        mint();
      }
    };
    mintButton.onclick = mint;
  }

  function setTotalPrice() {
    const mintInput = document.getElementById("mintInput");
    const mintInputValue = parseInt(mintInput.value);
    const totalPrice = document.getElementById("totalPrice");
    if (
      mintInputValue < 1 ||
      mintInputValue > window?.info.deploymentConfig.tokensPerMint
    ) {
      totalPrice.innerText = "INVALID QUANTITY";
      setMintDisabled(true);
      mintInput.disabled = true;
      return;
    }
    const totalPriceWei =
      window?.info.deploymentConfig.mintPrice * mintInputValue;

    let priceType = "";
    if (chain === "rinkeby") {
      priceType = "ETH";
    } else if (chain === "polygon") {
      priceType = "MATIC";
    }
    const price = web3.utils.fromWei(totalPriceWei.toString(), "ether");
    totalPrice.innerText = `${price} ${priceType}`;
    setMintDisabled(false);
    mintInput.disabled = false;
  }

  async function mint() {
    const mintButton = document.getElementById("mintButton");
    setMintDisabled(true);
    const spinner = "<span>Waiting for transaction...</span>";
    mintButton.innerHTML = spinner;

    const amount = parseInt(document.getElementById("mintInput").value);
    const value = window?.info.deploymentConfig.mintPrice * amount;
    const publicMintActive = await window?.contract.methods
      .mintingActive()
      .call();
    const presaleMintActive = await window?.contract.methods
      .presaleActive()
      .call();

    if (publicMintActive) {
      // PUBLIC MINT
      try {
        const mintTransaction = await window?.contract.methods
          .mint(amount)
          .send({ from: window.address, value: value.toString() });
        if (mintTransaction) {
          if (chain === "rinkeby") {
            const url = `https://rinkeby.etherscan.io/tx/${mintTransaction.transactionHash}`;
            const mintedContainer = document.querySelector(".minted-container");
            const countdownContainer = document.querySelector(".countdown");
            const mintedTxnBtn = document.getElementById("mintedTxnBtn");
            mintedTxnBtn.href = url;
            countdownContainer.classList.add("hidden");
            mintedContainer.classList.remove("hidden");
          }
          console.log(
            "Minted successfully!",
            `Transaction Hash: ${mintTransaction.transactionHash}`
          );
        } else {
          const mainText = document.getElementById("mainText");
          mainText.innerText = mint_failed;
          mintButton.innerText = button_public_mint;
          // mintButton.disabled = false;
          setMintDisabled(false);

          console.log("Failed to mint!");
        }
      } catch (e) {
        const mainText = document.getElementById("mainText");
        mainText.innerText = mint_failed;
        mintButton.innerText = button_public_mint;
        // mintButton.disabled = false;
        setMintDisabled(false);

        console.log(e);
      }
    } else if (presaleMintActive) {
      // PRE-SALE MINTING
      try {
        const merkleData = await fetch(
          `/.netlify/functions/merkleProof/?wallet=${window.address}&chain=${chain}&contract=${contractAddress}`
        );
        const merkleJson = await merkleData.json();
        const presaleMintTransaction = await window?.contract.methods
          .presaleMint(amount, merkleJson)
          .send({ from: window.address, value: value.toString() });
        if (presaleMintTransaction) {
          if (chain === "rinkeby") {
            const url = `https://rinkeby.etherscan.io/tx/${presaleMintTransaction.transactionHash}`;
            const mintedContainer = document.querySelector(".minted-container");
            const countdownContainer = document.querySelector(".countdown");
            const mintedTxnBtn = document.getElementById("mintedTxnBtn");
            mintedTxnBtn.href = url;
            countdownContainer.classList.add("hidden");
            mintedContainer.classList.remove("hidden");
          }
          console.log(
            "Minted successfully!",
            `Transaction Hash: ${presaleMintTransaction.transactionHash}`
          );
        } else {
          const mainText = document.getElementById("mainText");
          mainText.innerText = mint_failed;
          mintButton.innerText = button_presale_mint_whitelisted;
          // mintButton.disabled = false;
          setMintDisabled(false);

          console.log("Failed to mint!");
        }
      } catch (e) {
        const mainText = document.getElementById("mainText");
        mainText.innerText = mint_failed;
        mintButton.innerText = button_presale_mint_whitelisted;
        // mintButton.disabled = false;
        setMintDisabled(false);
      }
    }
  }

  // function timer(ms) {
  //   return new Promise((res) => setTimeout(res, ms));
  // }

  // async function fetchWithRetry(url) {
  //   await timer(TIMEOUT);
  //   return new Promise((resolve, reject) => {
  //     const fetch_retry = async (_url) => {
  //       return fetch(_url)
  //         .then(async (res) => {
  //           const status = res.status;

  //           if (status === 200) {
  //             return resolve(res.json());
  //           } else {
  //             console.error(`ERROR STATUS: ${status}`);
  //             console.log("Retrying");
  //             await timer(TIMEOUT);
  //             fetch_retry(_url);
  //           }
  //         })
  //         .catch(async (error) => {
  //           console.error(`CATCH ERROR: ${error}`);
  //           console.log("Retrying");
  //           await timer(TIMEOUT);
  //           fetch_retry(_url);
  //         });
  //     };
  //     return fetch_retry(url);
  //   });
  // }

  // const checkOwner = async (account) => {
  //   if (account) {
  //     let isOwner = false;
  //     let page = 1;

  //     const data = await fetchWithRetry(
  //       `/.netlify/functions/isowner/?wallet=${account}&page=${page}`
  //     );

  //     console.log(data, "ownership data");

  //     isOwner =
  //       data.isOwner == undefined ? false : !isOwner ? data?.isOwner : isOwner;
  //     updateStatusText(isOwner, true);

  //     editions = [...data?.editions];
  //     let nextPage = data?.next_page;

  //     while (nextPage) {
  //       page = nextPage;
  //       const data = await fetchWithRetry(
  //         `/.netlify/functions/isowner/?wallet=${account}&page=${page}`
  //       );

  //       isOwner =
  //         data?.isOwner == undefined
  //           ? false
  //           : !isOwner
  //           ? data?.isOwner
  //           : isOwner;
  //       updateStatusText(isOwner, true);

  //       editions = [...editions, ...(data?.editions || [])];
  //       nextPage = data?.next_page;
  //     }

  //     updateStatusText(isOwner, false);
  //   }
  // };

  // function renderDots(dots) {
  //   let dotsString = "";
  //   for (let i = 0; i < dots; i++) {
  //     dotsString += ".";
  //   }
  //   return dotsString;
  // }

  // function updateStatusText(isOwner, checking) {
  //   const statusText = document.querySelector(".owner-status");
  //   if (checking) {
  //     if (isOwner) {
  //       statusText.innerText = `You do own ${COLLECTION_NAME}!! 😻 Let's see how many ${renderDots()}`;
  //     } else {
  //       statusText.innerText = `Checking to see if you own any ${COLLECTION_NAME} 😻 ${renderDots()}`;
  //     }
  //   } else {
  //     if (isOwner) {
  //       statusText.innerText = `You own ${editions?.length} ${COLLECTION_NAME}!! 😻`;
  //     } else {
  //       statusText.innerText = `You don't own any ${COLLECTION_NAME} 😿`;
  //     }
  //   }
  //   dots = dots === 3 ? 1 : dots + 1;
  // }

  return (
    <>
      <header>
        <div className="container">
          <div className="logo-container">
            <img
              className="logo-img"
              src={require("./images/header/logo.jpg")}
              alt="logo"
            />
            <p className="logo-text">Odysseus Club</p>
          </div>
          <div className="menu">
            <button
              disabled={walletButtonDisabled}
              onClick={() => onClick()}
              className="wallet-btn btn"
              id="connectWallet"
            >
              <span>{buttonText}</span>
            </button>
          </div>
        </div>
      </header>

      {/* {accounts && accounts.length > 0 && <div className="owner-status"></div>} */}

      <div id="banner">
        <img
          id="banner-img"
          src={require("./images/nfts/21.png")}
          alt="nftimage"
        />
        <div>
          <h1>
            Own your own piece of the Odysseus universe with a limited supply of
            300 NFTs and also gain access to a range of exclusive owner benefits
          </h1>
        </div>
      </div>

      {/* <section className="not-connected">
        <div style={{ width: "80%" }}>
          <h2 id="welcomeH2">Connect to MetaMask to Get Started</h2>
          <h1 id="welcomeH1">Welcome to the Odysseus NFT Project!</h1>
          <p id="welcomeP">
            The Odyesseus NFT Project is a decentralized, open-source project
            that aims to demonstrate how to develope and launch your own NFT
            Collection. Follow the{" "}
            <a
              href="https://youtube.com/odysseusofficial"
              target="_blank"
              rel="noopener noreferrer"
            >
              Odysseus
            </a>{" "}
            YouTube channel.
          </p>
        </div>
        <img src={require("./images/header/coins.png")} alt="coins" />
      </section> */}

      <div className="countdown show-card card">
        <div id="countdown">
          <ul className="count-down" data-date="">
            <li className="clock-item">
              <span className="count-number days">0</span>
              <p className="count-text">Days</p>
            </li>

            <li className="clock-item">
              <span className="count-number hours">0</span>
              <p className="count-text">Hour</p>
            </li>

            <li className="clock-item">
              <span className="count-number minutes">0</span>
              <p className="count-text">Min</p>
            </li>

            <li className="clock-item" style={{ borderRight: "none" }}>
              <span className="count-number seconds">0</span>
              <p className="count-text">Sec</p>
            </li>
          </ul>
          {/* <h2 id="subHeading">Pre-Sale Countdown</h2> */}
        </div>

        <h1 id="mainHeading">Minting will be opening soon!!</h1>
        <p id="mainText">A new batch of NFTs will be available very soon!</p>
        {/* <h1 id="mainHeading">NFT Drop Coming Soon!!</h1>
        <p id="mainText">A new batch of NFTs will be available very soon!</p> */}
        <a
          id="actionButton"
          href="https://discord.com/invite/Kpz54szEgp"
          target="_blank"
          rel="noopener noreferrer"
          className="hero-btn btn"
        >
          <span>Join the Community</span>
        </a>
        <div id="mintContainer" className="mint-container hidden">
          <div className="info-container">
            <div>
              <h3>Supply</h3>
              <p id="totalSupply">100</p>
            </div>
            <div>
              <h3>Price Per Mint</h3>
              <p id="pricePerMint">1 ETH</p>
            </div>
            <div>
              <h3>Max</h3>
              <p id="maxPerMint">5 per wallet</p>
            </div>
          </div>
          <div className="mint-qty">
            <span>
              <button
                className="input-number-decrement btn counter-btn"
                id="mintDecrement"
              >
                –
              </button>
              <input
                id="mintInput"
                className="input-number btn counter-number"
                type="number"
                value="1"
                min="1"
                max="5"
              />
              <button
                className="input-number-increment btn counter-btn"
                id="mintIncrement"
              >
                +
              </button>
            </span>
            <button id="setQtyMax" className="btn">
              SET MAX
            </button>
          </div>
          <div className="total-price-container">
            <h3>Total</h3>
            <p id="totalPrice">.001 ETH</p>
          </div>
          <button
            disabled={isMintDisabled}
            id="mintButton"
            className="hero-btn btn mint-btn"
          >
            Mint
          </button>
        </div>
      </div>

      <div className="minted-container card show-card hidden">
        <h1>🎉</h1>
        <h2>Minted!</h2>
        <p>Here's your trasaction link:</p>
        <a id="mintedTxnBtn" href="" target="_blank" rel="noopener noreferrer">
          <button className="hero-btn btn">View Transaction</button>
        </a>
        <p>View it in the collection:</p>
        <a
          id="mintedLinkBtn"
          href="https://testnets.opensea.io/collection/odysseus-club"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="hero-btn btn">View Collection</button>
        </a>
      </div>

      {/* <!-- roadmap --> */}

      <h1 className="grid-heading">Creators</h1>
      <section className="creator-section">
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCwoTj-pZgZZ8DInOXSSLMmA"
        >
          <div className="a-box">
            <div className="img-container">
              <div className="img-inner">
                <div className="inner-skew">
                  <img src={require("./images/nfts/9.png")} />
                </div>
              </div>
            </div>
            <div className="text-container">
              <h3>Odysseus</h3>
              {/* <div>
                This a demo experiment to skew image container. It looks good.
              </div> */}
            </div>
          </div>
        </a>
        <a target="_blank" href="https://www.tiktok.com/@plai.io?lang=en">
          <div className="a-box">
            <div className="img-container">
              <div className="img-inner">
                <div className="inner-skew">
                  <img src={require("./images/nfts/24.png")} />
                </div>
              </div>
            </div>
            <div className="text-container">
              <h3>Plai</h3>
            </div>
          </div>
        </a>
      </section>

      <h1 className="grid-heading">Roadmap</h1>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="timeline">
          <div className="outer">
            <div className="timeline-card">
              <div className="info">
                <h3 className="title">2022</h3>
                <p>Unreleased music tracks for holders</p>
              </div>
            </div>
            <div className="timeline-card">
              <div className="info">
                <h3 className="title">2023</h3>
                <p>Virtual music events for holders</p>
                <p>Physical artwork posters for holders</p>
              </div>
            </div>
            <div className="timeline-card">
              <div className="info">
                <h3 className="title">2024</h3>
                <p>Live music events for holders</p>
                <p>Metaverse assets</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 className="grid-heading">FAQs</h1>
      <section className="faq-section">
        <Accordion allowZeroExpanded allowMultipleExpanded>
          {FAQs.map((item) => (
            <AccordionItem className="faq-item" key={item.id}>
              <AccordionItemState>
                {(state) => {
                  return (
                    <AccordionItemHeading className="faq-heading">
                      <AccordionItemButton>
                        {item.question}{" "}
                        <span>{state.expanded ? "-" : "+"}</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                  );
                }}
              </AccordionItemState>
              <AccordionItemPanel className="faq-answers accordion__panel">
                {item.answers.map((val, uid) => (
                  <span key={uid} style={{ display: "inline" }}>
                    {val}
                  </span>
                ))}
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </section>

      <div id="spinner" className="dot-spin"></div>

      <footer>
        <div className="footer">
          <p>@2022 Odyesseus Club. All rights reserved.</p>
          <div>
            <a
              href="https://discord.com/invite/Kpz54szEgp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("./images/header/discord.webp")}
                alt="Discord"
              />
            </a>
            <a
              href="https://twitter.com/OdyesseusClub"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("./images/header/twitter.webp")}
                alt="Twitter"
              />
            </a>
            <a
              href="https://opensea.io/collection/Odysseus"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("./images/header/opensea.webp")}
                alt="Opensea"
              />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
}

export default MetaMaskAuth;
