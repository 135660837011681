export const FAQs = [
  {
   id: 0, 
   question: "What is the Odysseus Club",
   answers: [
     <p>
        The Odysseus Club is a community of vapor wave, futurist, and metaverse
        enthusiasts. The Odysseus Club is an NFT membership pass built on
        Polygon. With the Odysseus Club NFT you’ll get utilities across the
        digital and physical world.
      </p>,
    ],
  },
  {
    id: 1, 
    question: "How many Odysseus Club NFTs are there",
    answers: [
      <p>
        There will be a very limited supply of only 300 (three hundred) original
        Odysseus Club NFTs. After the public mint is released, the only way to
        get one will be on OpenSea, a secondary market.
      </p>,
    ],
  },
  {
    id: 2, 
    question: "What can I do with an Odysseus Club NFT",
    answers: [
      <p>
        An Odysseus Club NFT will grant you early access to unreleased music
        from your favorite vapor and synth wave artists. We have plans to host
        virtual events, live events, and much much more. Odysseus Club NFT
        holders will be the first in line to access these.
      </p>,
    ],
  },
  {
    id: 3, 
    question: "Can I sell or trade my Odysseus Club NFT",
    answers: [
      <p>
        Yes, just like many other NFTs Odysseus Club holders will be able to
        trade/sell theirs on the secondary market.
      </p>,
    ],
  },
  {
    id: 4, 
    question: "When can I buy an Odysseus Club NFT",
    answers: [
      <p>
        The whitelist pre-sale will release at 9:30am pst on April 27th. The
        remaining Odysseus Club NFTs will be available at 12:30pm pst the next
        day. They will be sold on a first come, first serve basis.
      </p>,
    ],
  },
  {
    id: 5, 
    question: "Where can I learn more about the project and community",
    answers: [
      <p>
        We have our communities on YouTube, Instagram, and TikTok, but now we
        have a Discord just for Odysseus Club! We know a lot of people in the
        vapor wave and NFT space, you never know who will pop in.
      </p>,
    ],
  },
  {
    id: 6, 
    question: "How can I buy an Odysseus Club NFT",
    answers: [
      <p>
        Buy Polygon on{" "}
        <a target={"_blank"} href="https://www.coinbase.com/price/polygon">
          Coinbase
        </a>
      </p>,
      <p>Transfer Polygon to your Metamask</p>,
      <p>
        Go to the Odysseus Club minting site and connect your{" "}
        <a target={"_blank"} href="https://www.metamask.io">
          Metamask
        </a>
      </p>,
      <p>
        We will be offering a mint on our site to our fans before fully
        releasing on Opensea. During the mint you can purchase a random Odysseus
        Club NFT which will be revealed in the following days.
      </p>,
      <p>
        Once your NFT is revealed owners will be able to view their NFT in their
        Metamask and get NFT holder exclusive experiences
      </p>,
      <p>
        You'll be able to sell, trade, and buy other NFTs from the collection on
        our Opensea page.
      </p>,
    ],
  },
];